var angular = require('angular');

/**
 * A service for tracking clicks and inputs for Newrelic.
 *
 * @ngInject
 */
function NewrelicClickTrackerService($document, NewrelicService) {
  var page;
  return {
    init: init
  };

  function init(options) {
    polyfillElementMatches();
    page = options.page;
    angular.element($document[0].body).on('click', handlePageClick);
  }

  /**
   * Event handler will listen to all click events that happen on the page.
   *
   * @param {Object} event
   */
  function handlePageClick(event) {
    if (isAnchorClick(event.target)) {
      handleAnchorClick(event);
    } else if (isInputClick(event.target)) {
      handleInputClick(event);
    }
  }

  /**
   * Finds element with a given tagName.
   *
   * @param {Object} event
   * @param {String} tagName
   */
  function findClickElement(event, tagName){
    var target = event.target;

    while (target){
      if (target.tagName === tagName) {
        return target;
      }
      target = target.parentNode;
    }
    return null;
  }

  function getNewRelicName(eventType) {
    return ['typekit', page, eventType].join('.');
  }
  /**
   * Handles clicking on links. Element's URL and class name are sent to New Relic.
   *
   */
  function handleAnchorClick(event) {
    var eventAnchorElement = findClickElement(event, 'A');
    if (eventAnchorElement != null) {
      var elementUrl = eventAnchorElement.href;
      var elementClass = eventAnchorElement.className;
      NewrelicService.addPageAction(getNewRelicName('click'), {elementUrl: elementUrl, elementClass: elementClass});
    }
  }

  /**
   * Handles input fields. User's input is sent to New Relic.
   *
   */
  function handleInputClick(event) {
    var inputField = event.target;
    if (inputField.type === 'submit') {
      var parentFormElement = findClickElement(event, 'FORM');
      inputField = parentFormElement.querySelector('input[type=text]');
    }
    NewrelicService.addPageAction(getNewRelicName('input'), {inputValue: inputField.value, inputName: inputField.name});
  }

  function isAnchorClick(targetElement) {
    return targetElement.matches('a, a *');
  }

  function isInputClick(targetElement) {
    return targetElement.matches('input');
  }

  /**
  * Polyfill the Element.matches method for older browsers that don't natively
  * support it.
  */
  function polyfillElementMatches() {
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
    }
  }
}

module.exports = NewrelicClickTrackerService;
